import { axiosService } from "@/services/AxiosService.js";

class AssetService {
    
    constructor() {
        this.subPath = "assets";
    }

    save(entity) {
        let actionPath = `${this.subPath}/save`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    add(entity) {
      let actionPath = `${this.subPath}/add`;
      console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    dispose(entity) {
        let actionPath = `${this.subPath}/dispose`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    addWork(entity) {
        let actionPath = `${this.subPath}/work/add`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    removeWork(entity) {
      let actionPath = `${this.subPath}/work/remove`;
      console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }
  
    addMaintenace(entity) {
        let actionPath = `${this.subPath}/maintenance/add`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

  get(assetId) {
    let actionPath = `${this.subPath}/get/${assetId}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);

    }

    active(projectId) {
      let actionPath = `${this.subPath}/active/${projectId}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);

    }

  find(projectId, parameters) {
      let actionPath = `${this.subPath}/find/${projectId}`;
      console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, parameters);
    }

    delete(assetId) {
      let actionPath = `${this.subPath}/del/${assetId}`;
      console.log(`Delete to: ${actionPath}`);

      return axiosService.doDelete(actionPath);
    }
    
}

export const assetService = new AssetService();
