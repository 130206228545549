<template>
    <form id="frmNew" name="frm-new" ref="frmNew">
        <PrimeDialog v-model:visible="visible" modal header="Add a New Asset" @hide="fnClose"
                     :draggable="false" :style="{ width: '50rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div v-if="loading">
                <loader-mini-component></loader-mini-component>
            </div>
            <div>
                <div class="w-full mb-2">
                    <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400 ">
                        <div class="w-full flex-row">
                            <div class="w-full flex flex-row pb-2">
                                <div class="w-1/4 font-bold"><div class="mt-2 mr-2">Asset name:</div></div>
                                <div class="w-3/4">
                                    <div v-if="v$.entity.name.$error" class="text-red-600 text-sm">Please enter a Name for the Asset.</div>
                                    <InputText type="text" v-model="entity.name" class="w-full" placeholder="Asset Name"
                                                :invalid="v$.entity.name.$error" />
                                </div>
                            </div>
                            <div class="w-full flex flex-row pb-2">
                                <div class="w-1/4 font-bold align-middle"><div class="mt-2 mr-2">Company Id:</div></div>
                                <div class="w-3/4">
                                    <div v-if="v$.entity.companyId.$error" class="text-red-600 text-sm">Please enter the Company Id for the Asset.</div>
                                    <InputText type="text" v-model="entity.companyId" class="w-full" placeholder="Company Identifier"
                                                :invalid="v$.entity.companyId.$error" />
                                </div>
                            </div>
                            <div class="w-full flex flex-row pb-2">
                                <div class="w-1/4 font-bold align-middle"><div class="mt-2 mr-2">Asset Type:</div></div>
                                <div class="w-3/4">
                                    <span v-if="v$.entity.assetType.$error" class="text-red-600 text-sm">Please select the Asset Type.</span>
                                    <Dropdown v-model="entity.assetType" :options="assetTypes" optionLabel="text" optionValue="code" placeholder="Please Select a Type"
                                                class="w-full" :invalid="v$.entity.assetType.$error" />
                                </div>
                            </div>
                            <div class="w-full flex flex-row pb-2">
                                <div class="w-1/4 font-bold align-middle"><div class="mt-2 mr-2">Asset factory lifetime:</div></div>
                                <div class="w-3/4">
                                    <div v-if="v$.entity.lifetimeSpan.$error" class="text-red-600 text-sm mb-2">
                                        Please enter the factory lifetime (a valid positive number greater than 0).
                                    </div>
                                    <InputNumber v-model="lifetimeSpan" placeholder="Asset Lifetime" inputClass="w-36" />
                                </div>
                            </div>
                            <div class="w-full flex flex-row pb-2">
                                <div class="w-1/4 font-bold align-middle"><div class="mt-2 mr-2">Asset operational time:</div></div>
                                <div class="w-3/4">
                                    <div v-if="v$.entity.operationalTime.$error" class="text-red-600 text-sm mb-2">
                                        Please enter the Operational Time (a valid positive number greater than 0).
                                    </div>
                                    <InputNumber v-model="operationalTime" placeholder="Operational Time" inputClass="w-36" />
                                </div>
                            </div>
                            <div class="w-full flex flex-row pb-2">
                                <div class="w-1/4 font-bold align-middle"><div class="mt-2 mr-2">Lifetime left:</div></div>
                                <div class="w-3/4">
                                    <div class="mt-2">{{ entity.lifetimeLeft }}</div>
                                </div>
                            </div>
                            <div class="w-full flex flex-row">
                                <div class="w-1/4 font-bold align-middle"><div class="mt-2 mr-2">Notes:</div></div>
                                <div class="w-3/4">
                                    <Textarea v-model="entity.notes" class="w-full" rows="5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Back" title="Close window" severity="secondary" class="mr-2"
                        @click="fnClose" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
                <Button label="Add" title="Add a new Asset."
                        @click="fnSave" icon="pi pi-plus-circle" iconPos="right" />
            </template>
        </PrimeDialog>
    </form>
</template>

<script>
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
    import InputText from "primevue/inputtext";
    import InputNumber from "primevue/inputnumber";
    import Dropdown from "primevue/dropdown";
    import Textarea from "primevue/textarea";

    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core'
    import { required, minValue, integer } from '@vuelidate/validators'

    import Constants from "@/utils/Constants.js";
    import { assetService } from "@/services/AssetService.js";

    import LoaderMiniComponent from '@/components/LoaderMiniComponent'

    export default {
        name: "AssetNewComponent",
        emits: ["onError", "onClose", "onSave"],
        setup() {
            return { v$: useVuelidate({ $scope: "new" }) }
        },
        components: {
            Button,
            PrimeDialog,
            LoaderMiniComponent,
            InputText, InputNumber, Dropdown,
            Textarea
        },
        props: {
            isOpen: Boolean
        },
        data() {
            return {
                visible: false,
                loading: false,
                entity: {
                    assetId: undefined,
                    disposed: false,
                    name: undefined,
                    companyId: undefined,
                    lifetimeSpan: undefined,
                    operationalTime: 0,
                    lifetimeLeft: 0,
                    reason: "",
                    createdBy: undefined,
                    dispose: { },
                    workLog: [],
                    notes: undefined
                },
                lifetimeSpan: undefined,
                operationalTime: 0,
                assetTypes: Constants.Assets.TYPE,
            }
        },
        validations() {
            return {
                entity: {
                    name: { required },
                    companyId: { required },
                    lifetimeSpan: { required, minValue: minValue(1), integer },
                    assetType: { required },
                    operationalTime: { required, minValue: minValue(0), integer }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                console.log("Dispose Asset Component Opened!");
                this.visible = val;
            },
            lifetimeSpan: function (val) {
                this.entity.lifetimeSpan = val;
                this.entity.lifetimeLeft = val - this.entity.operationalTime;

                if (Number.isNaN(this.entity.lifetimeLeft)) { 
                    this.entity.lifetimeLeft = 0;
                }
            },
            operationalTime: function (val) {
                this.entity.operationalTime = val;
                this.entity.lifetimeLeft = this.entity.lifetimeSpan - val;

                if (Number.isNaN(this.entity.lifetimeLeft)) { 
                    this.entity.lifetimeLeft = 0;
                }
            }
        },
        methods: {
            fnClose() {
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    assetId: undefined,
                    projectId: undefined,
                    disposed: false,
                    name: undefined,
                    companyId: undefined,
                    lifetimeSpan: undefined,
                    operationalTime: 0,
                    lifetimeLeft: 0,
                    reason: "",
                    createdBy: undefined,
                    dispose: {},
                    workLog: []
                };
                this.lifetimeSpan = undefined;
                this.operationalTime = 0;
                this.$refs.frmNew.reset();
                this.v$.$reset();
            },
            fnSave() {
                this.v$.$validate()
                    .then((result) => {
                        //console.log("fnsave", result);
                        if (result) {
                            this.loading = true;
                            this.entity.projectId = this.$store.getters.project;
                            this.entity.createdBy = this.$store.getters.username;
                            
                            assetService.add(this.entity)
                                .then((data) => {
                                    //console.log(data);
                                    this.entity.assetId = data.upserted_id;
                                    this.$emit('onSave', data.asset);
                                    this.loading = false;
                                    this.fnClose();
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('onError', 'Unexpected Error', 'An error occurred when trying to Add the Asset!!!', ToastSeverity.ERROR);
                                    this.loading = false;
                                });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                return false;
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
        }
    };
</script>
