<template>
    <div role="status" v-if="isLoading">
        <loader-mini-component></loader-mini-component>
    </div>
    <form id="frmIncident" name="frm-incident" ref="frmIncident">
        <div class="px-5 py-2 flex-auto text-left">
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-fit mx-auto my-2 ">
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">
                    <tbody>
                        <tr class="flex  flex-row">
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-50 font-bold">Issue Number:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-10">
                                    {{ (entity.issueNumber > 0) ? entity.issueNumber.toString() : "-" }}
                                </div>
                            </td>
                            <td class="p-2">
                                <span class="inline-block lg:w-28 w-50 font-bold">Status:</span>
                            </td>
                            <td class="p-2">
                                <div class="lg:w-64 w-10 flex flex-wrap">
                                    <div class="mr-auto">
                                        {{ fnCapText(entity.status) }}
                                    </div>
                                    <!--pi-check-circle pi-times-circle-->
                                    <div class="ml-auto" v-if="isEditing && entity.status == statusList.OPEN">
                                        <Button icon="pi pi-ban" outlined rounded severity="warning" size="small" title="Cancel Incident"
                                                class="ml-2 dark:text-orange-300 dark:border-orange-300"
                                                @click="fnOpenCancel" />
                                        <Button icon="pi pi-check-square" outlined rounded severity="success" size="small" title="Resolve incident"
                                                class="ml-2 dark:text-green-300 dark:border-green-300"
                                                @click="fnOpenResolve" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="flex flex-col lg:flex-row">
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Title:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full ">
                                    <div v-if="v$.entity.title.$error" class="text-red-600 text-sm">Please enter the incident name.</div>
                                    <InputText type="text" v-model="entity.title" class="w-full"
                                               :invalid="v$.entity.title.$error" />
                                </div>
                            </td>
                            <td class="p-2">
                                <span class="inline-block lg:w-28 w-full font-bold">Date:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-64 w-full ">
                                    <div v-if="v$.entity.raisedDate.$error" class="text-red-600 text-sm">Please enter the date.</div>
                                    <Calendar id="calendar-24h" v-model="raisedDate" hourFormat="24" dateFormat="yy-mm-dd"
                                              :invalid="v$.entity.raisedDate.$error" class="w-full" />
                                </div>
                            </td>
                        </tr>
                        <tr class="flex flex-col lg:flex-row">
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Description:</span>
                            </td>
                            <td class="p-2 w-full" colspan="3">
                                <div v-if="v$.entity.description.$error" class="text-red-600 text-sm">Please enter the incident description.</div>
                                <Textarea v-model="entity.description" class="w-full" rows="5"
                                          :invalid="v$.entity.description.$error" />
                            </td>
                        </tr>
                        <tr class="flex flex-col lg:flex-row">
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Category:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">
                                    <span v-if="v$.entity.category.$error" class="text-red-600 text-sm">Please select a category.</span>
                                    <Dropdown v-model="entity.category" :options="categoryList" optionLabel="text" optionValue="code" placeholder="Select Category"
                                              class="w-full" :invalid="v$.entity.category.$error" />
                                </div>
                            </td>
                            <td class="p-2">
                                <span class="inline-block lg:w-28 w-full font-bold">Priority:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-64 w-full">
                                    <span v-if="v$.entity.priority.$error" class="text-red-600 text-sm">Please select the priority.</span>
                                    <Dropdown v-model="entity.priority" :options="priorityList" optionLabel="text" optionValue="code" placeholder="Select Priority"
                                              class="w-full" :invalid="v$.entity.priority.$error" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="px-5 py-2 flex-auto text-left">
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-fit mx-auto my-2 w-full">
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">
                    <tbody>
                        <tr class="flex lg:flex-row">
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Responsible role:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">
                                    <span v-if="v$.entity.responsibleRole.$error" class="text-red-600 text-sm">Please select a responsible.</span>
                                    <Dropdown v-model="entity.responsibleRole" :options="roleList" optionLabel="text" optionValue="code" placeholder="Select Role"
                                              class="w-full" :invalid="v$.entity.responsibleRole.$error" />
                                </div>
                            </td>
                            <td class="p-2">
                                <span class="inline-block lg:w-28 w-full font-bold">Due Date:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-64 w-full">
                                    <div v-if="v$.entity.dueDate.$error" class="text-red-600 text-sm">Please enter the Due date.</div>
                                    <Calendar id="calendar-24h" v-model="dueDate" hourFormat="24" dateFormat="yy-mm-dd"
                                              :invalid="v$.entity.dueDate.$error" class="w-full" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
    <div class="px-5 py-2 flex-auto text-left" v-if="entity.status == statusList.CLOSED">
        <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 w-fit mx-auto my-2">
            <table class="table-widget">
                <tbody>
                    <tr>
                        <td class="p-2">
                            <span class="inline-block w-32 font-bold">Closed Date:</span>
                        </td>
                        <td class="p-2">
                            <div class="inline-block w-64">
                                {{ fnFormatTextDate(entity.closeDate) }}
                            </div>
                        </td>
                        <td class="p-2">
                            <span class="inline-block w-32 font-bold">Closed Reason:</span>
                        </td>
                        <td class="p-2">
                            <div class="inline-block w-64">
                                {{ fnCapText(entity.closeReason) }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-2">
                            <span v-if="reasonList.RESOLVED==entity.closeReason" class="inline-block w-32 font-bold">Actions Taken:</span>
                            <span v-else class="inline-block w-32 font-bold">Close reasons:</span>
                        </td>
                        <td class="p-2" colspan="3">
                            <div class="inline-block w-full">
                                {{ entity.closeComments }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="pt-2 px-2 lg:w-[56rem]  mx-auto mt-2 flex flex-wrap">
        <div class="ml-auto">
            <Button label="Back" title="Go to Alerts page" severity="secondary" class="mr-2"
                    @click="fnCancel" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
            <!--<Button v-if="isEditing" label="Delete" title="Delete Alert." severity="danger" class="mr-2"
                    @click="fnOpenConfirmBox" icon="pi pi-times-circle" iconPos="right" />-->
            <Button v-if="!isEditing" label="Add" title="Add a new Alert."
                    @click="fnSave" icon="pi pi-plus-circle" iconPos="right" />
            <Button v-if="isEditing && (entity.tasks == undefined || entity.tasks.length < 1)" label="Save" title="Save changes."
                    @click="fnSave" icon="pi pi-check-circle" iconPos="right" />
        </div>
    </div>
    <incident-close-component :isOpen="closeComp.isOpen"
                              :entityId="closeComp.entityId"
                              :entityName="closeComp.entityName"
                              :closeReason="closeComp.closeReason"
                              @onCancel="fnCloseCancel"
                              @onClose="fnCloseClose"
                              @onError="fnShowToast"></incident-close-component>
</template>
<script>
    import Button from 'primevue/button';
    import Dropdown from 'primevue/dropdown';
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import Calendar from 'primevue/calendar';

    import { ToastSeverity } from 'primevue/api';

  import {
    formatNumber,
    formatDate,
    dateTimeToString,
    stringHasValue,
    capitalizeFirstLetter
  } from "@/utils/utils.js";

    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    import LoaderMiniComponent from '@/components/LoaderMiniComponent'
    import IncidentCloseComponent from "@/components/Incident/IncidentCloseComponent";

    import { incidentService } from "@/services/IncidentService.js";
    import Constants from "@/utils/Constants.js";

    export default {
        name: "IncidentDetailsComponent",
        emits: ["onError", "onCancel", "onSave", "onAdd", "onDelete", "onSetName"],
        setup() {
            return { v$: useVuelidate({ $scope: "details" }) }
        },
        components: {
            Button, Calendar, 
            InputText, Dropdown,
            Textarea,
            LoaderMiniComponent,
            IncidentCloseComponent
        },
        props: {
            entityId: String,
            isOpen: Boolean
        },
        data() {
            return {
                isLoading: false,
                visible: false,
                isEditing: false,
              raisedDate: new Date(),
              dueDate: new Date(),
              entity: {
                    incidentId: undefined,
                    projectId: undefined,
                    issueNumber: -1,
                    title: undefined,
                    description: undefined,
                    category: undefined,
                    priority: undefined,
                    status: Constants.Incident.STATUS.OPEN,
                    responsibleRole: undefined,
                    raisedDate: undefined,
                    dueDate: undefined,
                    resolvedDate: undefined,
                    updatedBy: undefined
                },
                closeComp: {
                    isOpen: undefined,
                    entityId: undefined,
                    entityName: undefined,
                    closeReason: undefined,
                    closeComments: undefined
                },
                categoryList: Constants.Incident.CATEGORIES,
                priorityList: Constants.Incident.PRIORITIES,
                roleList: Constants.Incident.ROLES,
                statusList: Constants.Incident.STATUS,
                reasonList: Constants.Incident.CLOSE_REASON
            }
        },
        validations() {
            return {
                entity: {
                    title: { required },
                    raisedDate: { required },
                    description: { required },
                    category: { required },
                    priority: { required },
                    responsibleRole: { required },
                    dueDate: { required }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {
                    this.visible = true;
                    this.isEditing = false;
                    console.log("Incident Component Opened! - ", this.entityId);
                    this.entity.incidentId = this.entityId;

                    if (stringHasValue(this.entityId)) {
                        this.isEditing = true;
                        this.fnLoadEntity();
                    }
                    else {
                      this.entity.projectId = this.$store.getters.project;
                      this.raisedDate = new Date();
                    }
                }
          },
          raisedDate: function (val) {
            this.entity.raisedDate = dateTimeToString(val);
          },
          dueDate: function (val) {
            this.entity.dueDate = dateTimeToString(val);
          },
        },
        methods: {
            fnLoadEntity() {
                this.isLoading = true;
                incidentService.get(this.entityId)
                    .then((data) => {
                        //console.log(data);
                      this.entity = data;
                      this.raisedDate = new Date(data.raisedDate);
                      this.dueDate = new Date(data.dueDate);
                      this.$emit('onSetName', this.entity.title);
                      this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', 'Error loading.', 'An error occurred when trying to Load the Incident: "' + this.entityId + '"!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnCancel() {
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onCancel');
            },
            fnSave: function () {
                // Do some stuff
                this.v$.$validate()
                    .then((result) => {
                        console.log("fnsave", result);
                        if (result) {
                            console.log("fnsave", result);
                            this.isLoading = true;
                            this.entity.updatedBy = this.$store.getters.username;
                            
                            incidentService.save(this.entity).then((data) => {
                                console.log("incidentService", data);
                                if (!this.isEditing) {

                                    this.entity.incidentId = data.upsertedId;
                                    this.entity.issueNumber = data.issueNumber; 
                                    this.$emit('onAdd', this.entity);
                                }
                                else {
                                    this.$emit('onSave', this.entity);
                                }

                                this.isEditing = true;
                                this.isLoading = false;
                                return true;
                            })
                            .catch(error => {
                                console.log(error);
                                this.$emit('onError', 'Error saving.', 'An error occured when trying to save the Incident!', ToastSeverity.ERROR);
                                this.isLoading = false;
                            });
                        }
                        //else {
                        //    console.log(this.v$.entity.name);
                        //}

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                return false;
            },
            fnOpenConfirmBox: function () {
                //this.confirmObj.message = "Do you really want to delete the alert: '" + this.entity.name + "'?";
                //this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                //this.confirmObj.message = "";
                //this.confirmObj.isOpen = false;
            },
            fnTaskOpen() {
                this.openTaskWindow = true;
            },
            fnTaskClose() {
                this.openTaskWindow = false;
            },
            fnAssignedTask(assigned, incidentName){
                //TODO: Add Task to Array.
                
                this.fnShowToast('Assign Success', `The Incident "${incidentName}" has been added to the task "${assigned.task}".`, ToastSeverity.SUCCESS);

                if (this.entity.tasks == undefined || !Array.isArray(this.entity.tasks))
                {    
                    this.entity.tasks = [];
                }
                this.entity.tasks.push(assigned);
                this.entity.quantity.used += assigned.quantity;
                this.entity.quantity.remaining -=  assigned.quantity;   
            },
            fnRecipeChanged(event){

                let obj = this.recipeList.find(o => o.id === event.value);

                this.entity.recipeName = obj.name;
                //console.log(JSON.stringify(obj));
            },
            fnCleanForm: function () {
                this.entity = {
                    incidentId: undefined,
                    projectId: undefined,
                    issueNumber: -1,
                    title: undefined,
                    description: undefined,
                    category: undefined,
                    priority: undefined,
                    status: Constants.Incident.STATUS.OPEN,
                    responsibleRole: undefined,
                    raisedDate: undefined,
                    dueDate: undefined,
                    resolvedDate: undefined,
                    updatedBy: undefined
                };
                this.$refs.frmIncident.reset();
                this.v$.$reset();
            },
            fnOpenCancel() {
                this.closeComp.entityId = this.entity.incidentId;
                this.closeComp.entityName = this.entity.title;
                this.closeComp.closeReason = Constants.Incident.CLOSE_REASON.CANCELLED;
                this.closeComp.isOpen = true;
            },
            fnOpenResolve() {
                this.closeComp.entityId = this.entity.incidentId;
                this.closeComp.entityName = this.entity.title;
                this.closeComp.closeReason = Constants.Incident.CLOSE_REASON.RESOLVED;
                this.closeComp.isOpen = true;
            },
            fnCloseClose(closedData) {
                this.entity.status = Constants.Incident.STATUS.CLOSED;
                this.entity.closeComments = closedData.closeComments;
                this.entity.closeReason = closedData.closeReason;
                this.entity.closedBy = closedData.closedBy;
                this.entity.closeDate = closedData.closeDate;
                this.fnShowToast("Incidente Closed.", `The incident '${this.entity.title}' was closed.`, ToastSeverity.WARN);
                this.fnCloseCancel();
            },
            fnCloseCancel() {
                this.closeComp.entityId = undefined;
                this.closeComp.entityName = undefined;
                this.closeComp.closeReason = undefined;
                this.closeComp.closeComments = undefined;
                this.closeComp.isOpen = false;
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatTextDate(textValue) {
                return formatDate(new Date(textValue));
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            }
        }
    };

</script>
