<template>
  <div>
    <Card class="" :style="{ backgroundColor: dynamicColor }">
        <template #title>
            <div class="text-base dark:text-slate-600">{{ header }}</div>
            <div class="text-xs  dark:text-slate-600">(last 30 days)</div>
        </template>
      <template #content>
        <div class="m-0 text-center font-bold" style="font-size: 40px">
            <span v-if="conf.link != undefined && conf.link.show" @click="bigTextClicked" class="hover:underline hover:cursor-pointer dark:text-slate-600">{{ data }}</span>
            <span v-else class="dark:text-slate-600">{{ data }}</span>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import Card from "primevue/card";

export default {
  components: {
    Card,
  },
  emits: ["componentClick"],
  props: {
    chartObject: Object,
    url: String,
    reload: Number
  },
  data() {
    return {
      data: "",
      header: "",
      chart: "",
      dynamicColor: "white",
      conf: {},
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
          //console.log("--------- BigText - New Value: ", newValue);
        this.header = newValue.name;
        this.chart = newValue;
        this.getData();
      },
    },
    reload: function (val) {
        this.getData(val);
      },
  },
  methods: {
      getData() {
          //console.log("--------- BigText - getData:", `${this.$apiBase}/${this.url}`);
        axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          this.data = response.data.data;
          this.conf = response.data.conf;
          this.dynamicColor =
            "bgcolor" in this.conf ? `#${this.conf.bgcolor}` : "#ffffff";
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      bigTextClicked() {
          if (this.conf != undefined && this.conf.link != undefined) {
              this.$emit("componentClick", { type: this.conf.link.type, key: this.conf.link.key, value: this.conf.link.value });
          }
          else {
              this.$emit("componentClick", { value: this.data });
          }
      }
  },
};
</script>
