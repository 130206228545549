<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Concrete Batch Details</h2>
    </div>
    <concrete-batch-details :entityId="entityId"
                     :isOpen="isOpen"
                     @onCancel="fnCancel"
                     @onSave="fnOnSave"
                     @onAdd="fnOnAdd"
                     @onDelete="fnOnDelete"
                     @onError="fnShowToast"></concrete-batch-details>
    <loader-component :isLoading="isLoading" type="primary"></loader-component>
</template>

<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import ConcreteBatchDetails from "@/components/Concrete/ConcreteBatchDetails";

    import { ToastSeverity } from 'primevue/api';

    export default {
        name: "BatchDetailsPage",
        components: {
            ConcreteBatchDetails,
            LoaderComponent
        },
        data() {
            return {
                isLoading: false,
                entityId: undefined,
                //entityName: undefined,
                isOpen: false
            };
        },
        created() {
            console.log("Concrete Batch Details Page: Created");

            this.entityId = this.$route.params.entityId;
            console.log("BatchId: " + this.$route.params.entityId);

            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Concrete Batch Details Page...");
        },
        methods: {
            fnLoad: function () {
                console.log("Concrete Batch  Details: loading - " + this.entityId);
                this.isLoading = true;
                this.isOpen = true;
                this.isLoading = false;
            },
            fnOnAdd(entitySaved) {
                this.fnShowToast('Save Success', 'The Concrete Batch  "' + entitySaved.batch + '" was inserted!', ToastSeverity.SUCCESS);
            },
            fnOnSave(entityNew) {
                this.fnShowToast('Save Success', 'The Concrete Batch  "' + entityNew.batch + '" was updated!', ToastSeverity.SUCCESS);
            },
            fnOnDelete(entityName) {

                this.fnShowToast('Delete Success', 'The Concrete Batch  "' + entityName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + alertName + '" was deleted!');
                this.fnCancel();
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCancel() {
                this.$router.push({ name: "batch-management" });
            }
        }
    };

</script>
