<!-- CreatePRoject.vue -->
<template>
    <div class="flex align-middle">
        <h2 class="text-4xl text-center m-auto text-bold">DSS Data Model Editor</h2>
        <Button type="button" label="Finalize" title="Finalize project creation" 
                class="ml-auto" @click="fnChangeProject"></Button>
    </div>
    <div class="fullscreen-iframe">
        <!--<button style="float: right;margin-right:10px;margin-bottom:10px" @click="fnChangeProject" 
            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"> Finalize </button>
        -->
        <iframe :src="iframeSrc" frameborder="0"></iframe>
    </div>
</template>
    
  <script>
      //import DynamicWidgetComponent from "@/components/DynamicWidgetComponent.vue";
      import { ifcService } from "@/services/IfcService.js";
      import Button from 'primevue/button';

  export default {
    name: "ProjectConfiguratorDataModelPage",
          //components: { Button, DynamicWidgetComponent },
          components: { Button },
    setup() {},
    data() {
      return{
        activeProject: "",
        file: null,
        drawflowBase: '',
        iframeMessage: ''
      }
        },
    created() {

      
      // Accessing global property and assigning it to local data
      this.drawflowBase = this.$drawflowBase;
      this.activeProject = this.$route.params.id;
    },
    computed: {
    iframeSrc() {      
      
      return this.drawflowBase+`/?activeProject=${encodeURIComponent(this.activeProject)}`;
    }
  },
    mounted() { 
        this.$logService.save(this.$options.name);
        // this.activeProject = this.$store.getters.project; 
      // window.addEventListener('message', this.handleIframeMessage);
    },
    beforeUnmount() {
    // Remove event listener when the component is destroyed
    // window.removeEventListener('message', this.handleIframeMessage);
    },
    methods:{
      handleFileChange(event) {
      this.file = event.target.files[0];
    },
    // handleIframeMessage(event) {
    //   this.iframeMessage = event.data;
    //   console.log(this.iframeMessage)
    //   // TODO:handle the redirectionHere not working ATM
    // },
    submitForm() {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);

        ifcService.uploadIFC(formData).then((data) => {
                            console.log(data);
                            return true;
                        }).catch(error => {
                                console.log(error);
                        });
        
       
      } else {
        console.error('No file selected');
      }
    },
    fnChangeProject() {
      //this.$store.commit("setProject", "");
        //this.$router.push({ name: 'project-info' });
        this.$emitter.emit("navigatePage", 'project-info');
    }
    }
  };
  
  </script>
    
  <style scoped>

  .fullscreen-iframe {
    position: fixed;
    top: 150px;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .fullscreen-iframe iframe {
    width: 100%;
    height: calc(100% - 175px);
  }
  
  </style>