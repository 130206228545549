import { axiosService } from "@/services/AxiosService.js";

class CatalogService {

    constructor() {
    }
    
    datasources(project) {
        let urlCat = `catalogs/datasource/list/${project}`
        console.log(`Get to: ${urlCat}`);
        
      return axiosService.doGet(urlCat);
    }

    users() {
        let urlUsers = `catalogs/user/list`
        console.log(`Get to: ${urlUsers}`);

      return axiosService.doGet(urlUsers);
    }

    projects() {
        let urlProjects = `catalogs/project/list`
        console.log(`Get to: ${urlProjects}`);

      return axiosService.doGet(urlProjects);
    }

  saveProject(projectObj) {
    let actionPath = `catalogs/project`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, projectObj);
  }

  deleteProject(projectId) {
    const actionPath = `catalogs/project/${projectId}`;
    console.log(`Delete to: ${actionPath}`);

    return axiosService.doDelete(actionPath);
  }
}

export const catalogService = new CatalogService();
