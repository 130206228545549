<template>
    <form id="frmTask" name="frm-task" ref="frmTask">
        <PrimeDialog v-model:visible="visible" modal header="Assign Incident to the Concrete batch." @hide="fnCancel"
                     :draggable="false" :style="{ width: '40rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div role="status" v-if="isLoading">
                <loader-mini-component></loader-mini-component>
            </div>
            <div class="mb-2">
                <div v-if="v$.incidentId.$error" class="text-red-600 text-sm mb-2">Please select an Incident.</div>
                <Dropdown v-model="incidentId" :options="incidentList" optionLabel="title" optionValue="incidentId" placeholder="Please Select an Incident"
                          class="w-full" :invalid="v$.incidentId.$error" @change="fnIncidentChanged" />
            </div>
            <div v-if="incident != undefined"
                    class="border-2 shadow-md rounded-md border-indigo-400 mb-2 p-2 w-full">
                <table class="table-widget w-full">
                    <tbody>
                        <tr>
                            <td class="p-2"><span class="font-bold">Issue #:</span></td>
                            <td class="p-2">{{ incident.issueNumber.toString() }}</td>
                            <td class="p-2"><span class="font-bold">Status:</span></td>
                            <td class="p-2">{{ fnCapText(incident.status) }}</td>
                        </tr>
                        <tr>
                            <td class="p-2"><span class="font-bold">Title:</span></td>
                            <td class="p-2">{{ incident.title }}</td>
                            <td class="p-2"><span class="font-bold">Date:</span></td>
                            <td class="p-2">{{ fnFormatDateTime(incident.raisedDate) }}</td>
                        </tr>
                        <tr>
                            <td class="p-2"><span class="font-bold">Category:</span></td>
                            <td class="p-2">{{ fnCapText(incident.category) }}</td>
                            <td class="p-2"><span class="font-bold">Priority:</span></td>
                            <td class="p-2">{{ fnCapText(incident.priority) }} </td>
                        </tr>
                        <tr>
                            <td class="p-2"><span class="font-bold">Description:</span></td>
                            <td class="p-2" colspan="3">{{incident.description}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template #footer>
                <Button label="Cancel" title="Cancel and go back" severity="secondary" class="mr-2"
                        @click="fnCancel" icon="pi pi-times-circle" iconPos="left"></Button>
                <Button label="Assign" title="Assign Incident" @click="fnAssign"
                        icon="pi pi-plus-circle" iconPos="right" />
            </template>
        </PrimeDialog>
    </form>
</template>

<script>
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
    import Dropdown from "primevue/dropdown";
    
    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    import { incidentService } from "@/services/IncidentService.js";
    import { capitalizeFirstLetter, formatNumber, formatStringDate } from "@/utils/utils.js";

    import LoaderMiniComponent from '@/components/LoaderMiniComponent.vue'

    export default {
        name: "AssignIncidentComponent",
        emits: ["onError", "onClose", "onAssign"],
        setup() {
            return { v$: useVuelidate({ $scope: "assign-incident" }) }
        },
        components: {
            Button,
            PrimeDialog,
            Dropdown,
            LoaderMiniComponent
        },
        props: {
            batchEntity: Object,
            isOpen: Boolean
        },
        data() {
            return {
                isLoading: false,
                visible: false,
                projectId: undefined,
                incident: undefined,
                incidentId: undefined,
                incidentList: []
            }
        },
        validations() {
            return {
                incidentId: { required }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {                    
                    this.isLoading = true;

                    console.log("Assign Incident Component Opened!");
                    this.projectId = this.$store.getters.project;
                    this.fnLoadIncidents();
                }
                this.visible = this.isOpen;
            }
        },
        methods: {
            fnLoadIncidents() {
                incidentService.getOpen(this.projectId)
                    .then((data) => {
                        //console.log(JSON.stringify(data));
                        this.incidentList = data;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', 'Error Loading', 'An error occurred when trying to load the list of incidents!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnIncidentChanged(event) {
                //console.log(event, event.value);
                this.incident = this.incidentList.find(o => o.incidentId === event.value);
            },
            fnAssign() {
                this.v$.$validate()
                    .then(result => {
                        //console.log(this.v$.alertObj.name.$errors);
                        
                        if (result) {
                            this.isLoading = true;
                            let assingIncident = {
                                linkedBy: this.$store.getters.username,
                                batchId: this.batchEntity.batchId,
                                incidentId: this.incidentId
                            };

                            incidentService.assignConcrete(assingIncident)
                                .then((data) => {
                                    //console.log(data);
                                    assingIncident.linkedDate = data.updConcrete.linkedDate;
                                    this.$emit('onAssign', assingIncident, this.incident);
                                    
                                    this.fnCancel()
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('onError', 'Error saving.', `An error occurred when trying to Assing the Incident "${this.incident.title}" to the Concrete batch!!!`, ToastSeverity.ERROR);
                                    this.isLoading = false;
                                });
                        }

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                return false;
            },
            fnCancel() {
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.incidentId = undefined
                this.incident = undefined;
                this.incidentList = new Array();
                
                this.$refs.frmTask.reset();
                this.v$.$reset();
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatDateTime(date) {
                //console.log(date);
                return formatStringDate(date);
            },
        }
    };
</script>
