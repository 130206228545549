import i18n from "@/plugins/i18n";

const isEmailValid = (email) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!email.match(validRegex)) {
    return false;
  }
  return true;
};

const isPasswordValid = (password) => {
  let error_list = [];
  if (password.length < 8) {
    error_list.push("Your password must be at least 8 characters");
  }
  if (password.search(/[a-z]/i) < 0) {
    error_list.push(
      "Your password must contain at least one lower case letter."
    );
  }
  if (password.search(/[A-Z]/i) < 0) {
    error_list.push("Your password must contain at upper case letter.");
  }
  if (password.search(/[0-9]/) < 0) {
    error_list.push("Your password must contain at least one digit.");
  }
  if (error_list.length > 0) {
    return { status: false, msg: error_list.join("\n") };
  }
  return { status: true };
};

const jwtDecode = (t) => {
  let token = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split(".")[0]));
  token.payload = JSON.parse(window.atob(t.split(".")[1]));
  return token;
};

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const capitalizeFirstLetter = (text) => {
  text = i18n.global.t(text);

  let result = "";
  if (text !== undefined && text.length > 0) {
    // Capitalize the first letter and add spaces between camelCase words
    result = text.charAt(0).toUpperCase() + text.slice(1);
    result = result.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  return result;
};

const processColumnObject = (obj) => {
  if (Array.isArray(obj)) {
    // If obj is an array, map through each element recursively
    return obj.map((item) => processColumnObject(item));
  } else if (typeof obj === "object" && obj !== null) {
    // If obj is an object, iterate through each key and process values
    const processedObj = {};
    for (const key in obj) {
      processedObj[key] = processColumnObject(obj[key]);
    }
    return processedObj;
  } else if (typeof obj === "string") {
    // If obj is a string, apply capitalizeAndReplace
    return capitalizeFirstLetter(obj);
  }
  // If it's neither an array, object, or string, return as-is
  return obj;
};

const formatNumber = (number) => {
  let text = new Intl.NumberFormat().format(number);
  return text;
};

const formatPercentage = (number) => {
  var option = {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  var formatter = new Intl.NumberFormat("en-GB", option);
  var text = formatter.format(number);

  return text;
};

const formatDate = (dateValue) => {
  let result = new Date(2000, 0, 1);
  if (dateValue != undefined && dateValue instanceof Date) {
    result = dateValue;
  }

  return result.toLocaleString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

const dateTimeToString = (dateValue, separator, parseTo) => {
  if (separator == undefined) {
    separator = 'T';
  }
  
  //let today = new Date(2000, 0, 1);
  if (dateValue != undefined && dateValue instanceof Date) {
    //today = dateValue;
    return dateValue;
  }

  let hh = dateValue.getHours();
  let mi = dateValue.getMinutes();
  let ss = dateValue.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (mi < 10) mi = "0" + mi;
  if (ss < 10) ss = "0" + ss;

  if (parseTo == "time") {
    return `${hh}:${mi}:${ss}`;
  }

  let yy = dateValue.getFullYear();
  let mm = dateValue.getMonth() + 1; // Months start at 0!
  let dd = dateValue.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  if (parseTo == "time") {
    return `${yy}-${mm}-${dd}`;
  }
  //console.log(dateValue.substring(0, 10));
  //return `${dd}/${mm}/${yy} ${hh}:${mi}:${ss}`;
  return `${yy}-${mm}-${dd}${separator}${hh}:${mi}:${ss}`;
};

const formatStringDateTime = (dateString) => {
  let result = dateString;
  //console.log(date);
  if (stringHasValue(dateString)) {
    if (dateString.indexOf("T") > 0) {
      result = dateString.replace("T", " ");
    }
    let pointIndex = dateString.indexOf(".");
    if (pointIndex > 0) {
      result = result.substring(0, pointIndex);
    }
  }
  return result;
};

const formatStringDate = (dateString) => {
  let result = dateString;
  //console.log(date);
  if (stringHasValue(dateString)) {
    let tIndex = dateString.indexOf("T");
    if (tIndex > 0) {
      result = dateString.substring(0, tIndex);
    }
  }
  return result;
};

const stringHasValue = (val) => {
  return val != undefined && typeof val == "string" && val.trim().length > 0;
};

const arrayHasValue = (arr) => {
  return arr != undefined && Array.isArray(arr) && arr.length > 0;
};

const numberIsValid = (val) => {
  let result = true;

  if (typeof val == "string") {
    result = !isNaN(val) && !isNaN(parseFloat(val));
  } else if (typeof val != "number") {
    result = false;
  }

  return result;
};

const valueIsTrue = (val) => {
  let result = val;
  if (typeof val == "string") {
    result = val.toLowerCase();
  }

  return (
    result == true ||
    result == 1 ||
    result == "true" ||
    result == "t" ||
    result == "y" ||
    result == "yes"
  );
};

const tryParseFloat = (val) => {
  if (["number", "float", "int"].includes(typeof val)) {
    return val;
  }
  let result = val;

  try {
    result = parseFloat(val);
  } catch (error) {
    // Code to handle the error
    console.error("An error occurred when parseFloat:", val, error.message);
  }

  return result;
};

const convertBase64ToImage = (base64String) => {
  const dataURIprefix = "data:image/";
  let dataURI = base64String;

  if (!base64String.startsWith(dataURIprefix)) {
    const mimeType = "image/png";
    dataURI = `${dataURIprefix}${mimeType};base64,${base64String}`;
  }

  return dataURI;
};

const updateRecords = (records, schema) => {
  for (let key in schema) {
    //console.log(key);
    if (schema[key] === 'datetime') {
      for (let item in records) {
        //console.log(records[item][key]);
        records[item][key] = new Date(records[item][key]);
      }
    }
  }
  return records;
};

export {
  isEmailValid,
  isPasswordValid,
  jwtDecode,
  parseJwt,
  capitalizeFirstLetter,
  processColumnObject,
  stringHasValue,
  arrayHasValue,
  numberIsValid,
  formatNumber,
  formatPercentage,
  formatDate,
  formatStringDateTime,
  formatStringDate,
  dateTimeToString,
  convertBase64ToImage,
  valueIsTrue,
  tryParseFloat,
  updateRecords
};
