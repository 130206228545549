import { axiosService } from "@/services/AxiosService.js";

class ConcreteBatchService {

    constructor() {
      this.subPath = "concrete-batches";
    }

    save(entity) {
        let actionPath = `${this.subPath}/save`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

  update(entity) {
    let actionPath = `${this.subPath}/update`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, entity);
  }

    get(entityId) {
      let actionPath = `${this.subPath}/get/${entityId}`;
      console.log(`Get to: ${actionPath}`);
      
      return axiosService.doGet(actionPath);
  }

  getBatch(batchBase){
    let actionPath = `${this.subPath}/get-batch/${batchBase}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }
  
    find(projectId) {
      let actionPath = `${this.subPath}/find/${projectId}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

    delete(entityId) {
      const actionPath = `${this.subPath}/delete/${entityId}`;
      console.log(`Delete to: ${actionPath}`);

      return axiosService.doDelete(actionPath);
    }

    assignTask(entity) {
      let actionPath = `${this.subPath}/assign-task`;
      console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);

    }

  assignConcrete(entity) {
    let actionPath = `${this.subPath}/assign-concrete`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, entity);

  }

}

export const concreteBatchService = new ConcreteBatchService();
