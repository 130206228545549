<template>
    <form id="frmDispose" name="frm-dispose" ref="frmDispose">
        <PrimeDialog v-model:visible="entity.isOpen" modal :header="textHeader" @hide="fnCancel"
                     :draggable="false" :style="{ width: '50rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <!-- Working date -->
            <div class="mb-2">
                <div v-if="v$.entity.workDate.$error" class="text-red-600 text-sm mb-2">Please enter a Working date.</div>
                <Calendar v-model="entity.workDate" showIcon iconDisplay="input" placeholder="Working day" inputClass="w-48" />
                <!-- Worked hours -->
            </div>
            <div>
                <div v-if="v$.entity.workHours.$error" class="text-red-600 text-sm mb-2">
                    Please enter the Hours worked for the asset <br />(a valid positive number greater than 0).
                </div>
                <InputNumber v-model="entity.workHours" placeholder="Worked hours" inputClass="w-48" />
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-arrow-circle-left" iconPos="left"
                        class="mr-2 bg-surface-400 border-surface-400 hover:bg-surface-500"
                        title="Cancel and go back" @click="fnCancel"></Button>
                <Button label="Dispose" icon="pi pi-times" iconPos="right"
                        title="Dispose asset" @click="fnDisposeAsset"></Button>
            </template>

        </PrimeDialog>
    </form>
</template>

<script>
    //import Textarea from 'primevue/textarea';
    import Calendar from 'primevue/calendar';
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
    import InputNumber from 'primevue/inputnumber';


    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core'
    // import { required} from '@vuelidate/validators'
    import { required, requiredIf } from '@vuelidate/validators'

    import { assetService } from "@/services/AssetService.js";
    import { capitalizeFirstLetter } from "@/utils/utils.js";
    import Constants from "@/utils/Constants.js";

    export default {
        name: "AssetMaintenanceComponent",
        emits: ["onError", "onClose", "onDispose"],
        setup() {
            return { v$: useVuelidate() }
        },
        components: {
            //Textarea,
            InputNumber,
            Calendar, Button,
            PrimeDialog
        },
        props: {
            assetId: String,
            assetName: String,
            isOpen: Boolean
        },
        data() {
            return {
                entity: {
                    assetId: undefined,
                    disposeType: undefined,
                    reason: "",
                    user: undefined,
                    isOpen: false
                },
                textHeader: ""
            }
        },
        validations() {
            return {
                entity: {
                    disposeType: { required },
                    //reason: { required }
                    reason: { requiredIf: requiredIf(function () { return this.entity.disposeType == Constants.Assets.DISPOSE_TYPE.FAILED; }) }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {                    
                    console.log("Asset Maintenance Component Opened!");
                    this.entity.assetId = this.assetId;
                    this.entity.isOpen = this.isOpen;
                    this.textHeader = "Maintenance Asset: " + this.assetName;
                }
            }
        },
        methods: {
            fnDisposeAsset() {
                this.v$.$validate()
                    .then(result => {
                        //console.log(this.v$.alertObj.name.$errors);
                        //console.log(this.$store.getters.username);
                        console.log(JSON.stringify(this.entity));
                        if (result) {
                            this.loading = true;
                            this.entity.user = this.$store.getters.username;
                            
                            assetService.dispose(this.entity)
                                .then((data) => {
                                    console.log(data);
                                    this.$emit('onDispose', this.data.upserted_id, this.assetName);

                                    this.loading = false;

                                    this.fnCleanForm();
                                    this.$emit('onClose');
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('Error', 'An error occurred when trying to dispose the Asset: "' + this.assetName + '"!!!', ToastSeverity.ERROR);
                                    this.loading = false;
                                });

                            this.loading = false;
                                
                            return true;
                        }
                        alert("Dispose the asset");
                        //else {
                        //    console.log(this.v$.alertObj.name);
                        //}

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                return false;
            },
            fnCancel() {
                this.fnCleanForm();
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    assetId: undefined,
                    disposeType: undefined,
                    reason: "",
                    user: undefined
                };
                this.$refs.frmDispose.reset();
                this.v$.$reset();
            },
            fnOpenConfirmBox: function () {
                this.confirmObj.message = "Do you really want to delete the alert: '" + this.alertObj.name + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
        }
    };
</script>
<style scoped>

</style>

