<template>
  <div class="card p-4 bg-gray-800 text-white rounded-lg">
    <div>
    <h1 v-if="selectedBatch" class="flex justify-center pt-3 pb-10 text-[28px] font-bold">Total Batch Tasks</h1>
    <h1 v-else class="flex justify-center pt-3 pb-10 text-[28px] font-bold">Total Concrete by Batches</h1>
    </div>
    <div class="flex flex-wrap justify-between gap-4">      
      <div class="date-range-selector w-full md:w-1/2">        
      <div class="flex items-center space-x-4">
        <button @click="toggleCalendar" class="text-lg font-bold mb-2">Filter by Date Range:</button>
        <span class="cursor-pointer text-xl mb-2">
          {{ selectedDateRangeLabel }}
        </span>
      </div>
      <Calendar
        v-if="calendarVisible"
        v-model="selectedDateRange"
        selectionMode="range"
        monthNavigator
        yearNavigator
        yearRange="2020:2030"
        dateFormat="mm/dd/yy"
        :inline="true"
        class="border border-gray-600 rounded-lg p-2 bg-gray-700"
      />
      </div>
      <div class="date-range-selector w-full md:w-1/2">
        <div class="flex items-center space-x-4">
        <label class="block text-lg font-bold mb-2">Filter by Batch:</label>  
        <span class="cursor-pointer text-xl mb-2">
          <Dropdown v-model="selectedBatch" :options="batches" optionLabel="name" placeholder="Select a Batch" class="w-1rem md:w-14rem" />      
        </span>              
      </div>      
    </div>
    <div>
      <button @click="removeChartFilters" class="w-full text-lg font-bold">Remove Filters</button>
    </div>
    </div>

    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="mt-6"
      style="height: 500px"
    />
  </div>
</template>


<script>
import Chart from "primevue/chart";
import Calendar from "primevue/calendar";
import Dropdown from 'primevue/dropdown';
import axios from "axios";


const textColor = "#f4f6f7";
const textColorSecondary = "#B2BEB5";
export default {
  components: {
    Chart,
    Calendar,
    Dropdown
  },
  props: {
    chartObject: Object,
    url: String,
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        this.chart = newValue;
        this.getData();
      },
    },
    selectedBatch: function() { 
      this.selectedDateRange = null
      
       this.getDataBatch()
    },
    selectedDateRange: function(){
      if(this.selectedDateRange[1])      
    {                        
      this.calendarVisible = !this.calendarVisible;  
      this.selectedBatch = null    
      const formatDateToCustomString = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const rawStartDate = new Date(this.selectedDateRange[0]);
    const rawEndDate = new Date(this.selectedDateRange[1])
    this.startDate = formatDateToCustomString(rawStartDate);
    this.endDate = formatDateToCustomString(rawEndDate)
    this.getData();
    }      
    }          
  },
  data() {
    return {
      selectedBatch: null,
      batches: "",
      selectedDateRange: null,      
      startDate: null,
      endDate:null,
      month:null,
      calendarVisible: false,
      monthCaldarVisible: false,
      chartData: {},
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: textColorSecondary,
            },
          },
        },
      },
    };
  },
  computed: {
    selectedDateRangeLabel() {
      if (this.selectedDateRange && this.selectedDateRange.length === 2) {
        return `${new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" }).format(this.selectedDateRange[0])} - ${new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" }).format(this.selectedDateRange[1])}`;
      }
      return "";
    },   
  },
  methods: {    
    toggleCalendar() {            
      this.calendarVisible = !this.calendarVisible;
    },     
    removeChartFilters() {
      this.selectedDateRange = null
      this.selectedBatch = null
      this.startDate = null
      this.endDate = null
      this.getData(); 
    },
    getData() {      
      axios
        .get(`${this.$apiBase}/${this.url}/${this.startDate}/${this.endDate}`)
        .then((response) => {                      
          const labels =  response.data.labels
          this.batches = labels.map((item) => {
            return{
              name:item
            }
          })                                                                   
          this.chartData = {
            labels: labels,
            datasets: [
              {
                label: "Produced",
                data: response.data.data1,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
              {
                label: "Used",
                data: response.data.data2,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
              {
                label: "Remaining",
                data: response.data.data3,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
            ],
          };
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },    
    getDataBatch() {
      axios
        .get(`${this.$apiBase}/${this.url}/${this.selectedBatch.name}`)
        .then((response) => {                                        
          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: response.data.conf.label,
                data: response.data.data,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },              
            ],
          };
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
  
};
</script>

<style>
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.filter-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #f4f6f7;
}
.date-range-selector {
  flex: 1;
}
.calendar-inline {
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 10px;
  background-color: #2c2f33; 
}
.date-range-label {
  cursor: pointer;
  font-size: 18px;
  display: block;
  color: #f4f6f7;
  margin-bottom: 10px;
}
</style>
