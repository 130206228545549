<template>
    <div v-for="(product, pro_key) in data"
         :key="pro_key"
         class="flex-0 mb-4"
         style="margin-bottom: 64px">
        <div class="m-2 w-full mb-2">
            <span class="font-bold dark:text-surface-200 text-[20px]">{{ pro_key }}</span>
        </div>
        <div class="bg-yellow-50 py-8 shadow-md mb-2"
             style="padding-top: 40px; padding-bottom: 40px">
            <div v-for="(sub_value, sub_key) in product" :key="sub_key">
                <div v-if="sub_key!='id'" class="mt-1 text-center font-semibold w-full mb-3 hover:cursor-pointer hover:underline" @click="onRowSelect(product)">
                    {{ sub_key }}
                </div>
                <div v-if="sub_key!='id'" class="flex flex-wrap mt-4 justify-center gap-4">
                    <div v-for="(value, key) in sub_value" :key="key">
                        <div class="mx-4 flex items-center">
                            <div class="flex items-center justify-center">
                                <Avatar icon="pi pi-wave-pulse"
                                        class="mr-2"
                                        shape="circle"
                                        style="background-color: #f8f7fc; color: #f82c7c" />
                            </div>
                            <div>
                                <div>
                                    <div class="m-0 text-center font-bold text-4xl">
                                        {{ value }}
                                    </div>
                                </div>
                                <div>
                                    <div class="text-base">{{ fnCapText(key) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <details-table-component :selectedRow="selectedRow" :showModel="showModel"
                        :imageUrl="detailsView.imageUrl" :showImageModel="detailsView.showImageModel" 
                        :updateCols="conf.updateCols" :enableEdit="conf.enableEdit"
                        :rowSchema="detailsView.rowSchema" :tableSource="detailsView.tableSource"
                        @onClose="fnDetailsClose" @updateRow="fnUpdateDoc" />
</template>

<script>
  import axios from "axios";

  import Avatar from "primevue/avatar";
  import DetailsTableComponent from "@/components/Tables/DetailsTableComponent.vue";

  import { capitalizeFirstLetter, updateRecords } from "@/utils/utils.js";
    
export default {
  components: {
    Avatar,
    DetailsTableComponent
  },
  emits: ["componentClick", "recordUpdated"],
  props: {
    chartObject: Object,
    url: Object,
    reload: Number,
    clickEvent: Object
  },
  data() {
    return {
      data: "",
      header: "",
      chart: "",
      dynamicColor: "white",
      conf: {},
      selectedRow: {},
      showModel: false,
      detailsView: {
        imageUrl: "",
        showImageModel: false,
        rowSchema: {},
        tableSource: undefined
      }
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Value: ", newValue);
        this.header = newValue.name;
        this.chart = newValue;
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      console.log("--------- BIG CARD ----------");
      let urlGet = `${this.$apiBase}/${this.url.onLoad}`;
      console.log(urlGet);
      axios
        .get(urlGet)
        .then((response) => {
          console.log("Response");
          //console.log(response.data);
          this.conf = response.data.conf;
          this.schema = response.data.schema;

          this.data = updateRecords(response.data.data, this.schema);

        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    onRowSelect(data) {
      var getUrl = `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${data.id}`;
      console.log(getUrl);
      axios
        .get(getUrl)
        .then((response) => {
            console.log("----- ROW -----");
            this.selectedRow = response.data.data;
            
            if (Object.keys(this.selectedRow).length > 0) {
                this.showModel = true;
            }
            else
            {
                alert("No data found");
            }
        })
        .catch((error) => {
            console.error("Error loading data:", error);
        });
    },
    fnDetailsClose() {
      //console.log("fnDetailsClose");
      this.showModel = false;
      this.detailsView.showImageModel = false;
    },
    fnUpdateDoc() { },

    fnCapText(text) {
        return capitalizeFirstLetter(text);
    },
    
  }
};
</script>
