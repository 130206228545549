<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">{{ this.assetType }} Details</h2>
    </div>
    <asset-details :assetId="entityId"
                     :isOpen="isOpen"
                     @onClose="fnCancel"
                     @onDispose="fnDispose"
                     @onWorkAdded="fnWorkAdded"
                     @onWorkRemoved="fnWorkRemoved"
                     @onSave="fnSave"
                     @setAssetType="fnAssetType"
                     @onError="fnShowToast"></asset-details>
    <loader-component :isLoading="isLoading" type="primary"></loader-component>
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import AssetDetails from "@/components/Assets/AssetDetailsComponent.vue";

    import { ToastSeverity } from 'primevue/api';
    import Constants from "@/utils/Constants.js";

    export default {
        name: "AssetDetailsPage",
        components: {
            AssetDetails,
            LoaderComponent
        },
        data() {
            return {
                isLoading: false,
                entityId: undefined,
                isOpen: false,
                assetType: "Asset"
            };
        },
        created() {
            console.log("Asset Details Page: Created");

            this.entityId = this.$route.params.entityId;
            console.log("AssetId: " + this.$route.params.entityId);

            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Asset Details Page...");
        },
        methods: {
            fnLoad: function () {
                console.log("Asset  Details: loading - " + this.entityId);
                this.isLoading = true;
                this.isOpen = true;
                this.isLoading = false;
            },
            fnWorkAdded(assetName) {
                //this.fnShowToast('Save Success', 'The Asset "' + entitySaved.batch + '" was inserted!', ToastSeverity.SUCCESS);
                this.fnShowToast('Add Work Success', `The working hours have been added to the ${this.assetType} "${assetName}".`, ToastSeverity.SUCCESS);
            },
            fnDispose(assetName) {
                //this.fnShowToast('Save Success', 'The Asset "' + entityNew.batch + '" was updated!', ToastSeverity.SUCCESS);
                this.fnShowToast('Dispose Success', `The ${this.assetType} "${assetName}" was disposed!`, ToastSeverity.WARN);
            },
            fnSave(entity) {
                //this.fnShowToast('Save Success', 'The Asset "' + entityNew.batch + '" was updated!', ToastSeverity.SUCCESS);
                this.fnShowToast('Save Success', `The ${this.assetType} "${entity.name}" was updated successfully!`, ToastSeverity.SUCCESS);
            },
            fnWorkRemoved(entityName) {
                //this.fnShowToast('Delete Success', `The ${this.assetType} "${entityName}" was deleted!`, ToastSeverity.WARN);
                this.fnShowToast('Remove Work Success', `The working hours have been removed from the ${this.assetType} '${entityName}'.`, ToastSeverity.WARN);
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCancel(assetType) {
                //console.log(assetType);
                //alert(assetType);
                if (assetType == Constants.Assets.TypeCode.ROBOT) { 
                    this.$router.push({ name: "robots" });
                }
                else
                {
                    this.$router.push({ name: "asset-management" });                    
                }
            },
            fnAssetType(assetType) {
                this.assetType = assetType;
            }
        }
    };

</script>
