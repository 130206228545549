<template>
  <div class="custom-rich-data-table">
    <div
      class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400"
    >
      <h3
        class="text-indigo-600 text-center text-xl dark:text-indigo-300"
        style="text-transform: uppercase; font-weight: bold"
      >
        {{ chart["name"] }}
      </h3>
      <div class="text-xs dark:text-surface-200">Click on each row to see details.</div>
      <DataTable
        stripedRows
        :value="tableRows"
        tableStyle="min-width: 35rem; font-size: 14px;"
        paginator
        :rows="5"
        :loading="loading"
        @rowSelect="onRowSelect"
        selectionMode="single"
        style="width: 100%"
      >
        <Column
          v-for="(column, colIdx) in columns"
          :key="colIdx"
          :field="column"
          :header="column"
        ></Column>
      </DataTable>
    </div>
    <div>
        <details-table-component :selectedRow="selectedRow" :showModel="showModel"
                                 :imageUrl="imageUrl" :showImageModel="showImageModel"
                                 :updateCols="conf.updateCols" :enableEdit="conf.enableEdit"
                                 :tableSource="tableSource"
                                 @onClose="fnDetailsClose" />
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
//import PrimeDialog from "primevue/dialog";
import DetailsTableComponent from "@/components/Tables/DetailsTableComponent.vue";

export default {
  components: {
    DataTable,
    Column,
    //PrimeDialog,
    DetailsTableComponent
  },
  props: {
    chartObject: Object,
    url: Object,
  },
  data() {
    return {
      columns: [],
      title: "",
      tableRows: [],
      chart: {},
      loading: false,
      showModel: false,
      selectedRow: {},
      base64String: "",
      imageUrl: "",
      showImageModel: "",
      schema: {},
      conf: {},
      tableSource: ""
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("tableTitle Chart:", newValue);
        this.chart = newValue;
        this.loadData();
      },
    },
  },
  methods: {
    onRowSelect(event) {
      console.log(event.data);
      axios
        .get(
          `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${event.data.id}`
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedRow = response.data.data;
          
          if ("image" in this.selectedRow) {
            this.base64String = this.selectedRow.image;
            this.convertBase64ToImage();
            this.showImageModel = true;
          } else if (Object.keys(this.selectedRow).length > 0) {
            this.showModel = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return [];
        });
    },
    async loadData() {
      this.loading = true;
      console.log("--------- LOAD DATA");
      console.log(`${this.$apiBase}/${this.url.onLoad}`);
      axios
        .get(`${this.$apiBase}/${this.url.onLoad}`)
        .then((response) => {
          console.log("-- NEW TABLE");
          //console.log(response.data);
          this.tableRows = response.data.data;
          this.columns = response.data.cols;
          
          this.schema = response.data.schema;
          this.conf = response.data.conf;
          
          if (this.conf.collection != undefined){
            this.tableSource = this.conf.collection;
          }
          else if (this.conf.type != undefined)
          {
              this.tableSource = this.conf.type;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return [];
        });
    },

    convertBase64ToImage() {
      const dataURIprefix = "data:image/";
      let dataURI = this.base64String;

      if (!this.base64String.startsWith(dataURIprefix)) {
        const mimeType = "image/png"; // Change to 'image/jpeg' or other type if needed
        dataURI = `${dataURIprefix}${mimeType};base64,${this.base64String}`;
      }

      this.imageUrl = dataURI;
    },
    fnDetailsClose() {
      this.showModel = false;
      this.showImageModel = false;
    },
  },
};
</script>

<style scoped></style>
