<!-- CreatePRoject.vue -->
<template>
    <h2 class="text-4xl leading-tight text-center">Create Project</h2>
    <div class="px-5 py-5 flex-auto dark:text-surface-200 dark:bg-surface-800">
        <form @submit.prevent="submitForm">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 ">
                    <label for="projectName">Project Name</label>
                    <FormInput id="projectName" v-model="createProjectForm.name" />
                    <div v-if="v$.createProjectForm.name.$error" class="text-red-600 text-sm">The Name is required</div>
                </div>
                <div class="col-span-6 ">
                    <label for="estimatedSize">Estimated size (m2 or m3):</label>
                    <FormInput id="estimatedSize" v-model="createProjectForm.estimatedSize" />
                    <div v-if="v$.createProjectForm.estimatedSize.$error" class="text-red-600 text-sm">The estimated size is required</div>
                </div>
                <div class="col-span-6 ">
                    <label for="comments">Noise level allowed (db)</label>
                    <FormInput id="comments" v-model="createProjectForm.noiceLevel" />
                    <div v-if="v$.createProjectForm.noiceLevel.$error" class="text-red-600 text-sm">The noice level is required</div>
                </div>
                <div class="col-span-6 ">
                    <label for="longitude">Longitude:</label>
                    <FormInput id="longitude" v-model="createProjectForm.longitude" />
                </div>
                <div class="col-span-6 ">
                    <label for="latitude">Latitude</label>
                    <FormInput id="latitude" v-model="createProjectForm.latitude" />
                </div>
                <div class="col-span-12">
                    <label for="comments">Comments</label>
                    <FormInput id="comments" v-model="createProjectForm.comments" />
                </div>

                <div class="col-span-12">
                    <label for="projectImage">Project Image</label>
                    <br>
                    <input type="file" id="projectImage" ref="projectImage" @change="handleImageChange">
                </div>

                <!--<div class="col-span-12">
                    <label for="workingWindow">Allowed Working Window</label>
                    <div class="p-inputgroup grid grid-cols-12 gap-6">
                        <div class="col-span-6 ">
                            <FormInput id="workingWindowStart" type="time" v-model="createProjectForm.workingWindowStart" />
                            <div v-if="v$.createProjectForm.workingWindowStart.$error" class="text-red-600 text-sm">The start time is required</div>
                        </div>
                        <div class="col-span-6 ">
                            <FormInput id="workingWindowEnd" type="time" v-model="createProjectForm.workingWindowEnd" />
                            <div v-if="v$.createProjectForm.workingWindowEnd.$error" class="text-red-600 text-sm">The end time is required</div>
                        </div>
                    </div>
                </div>-->
            </div>
            <div class="col-span-12">
                <br />
                <br />
                <Button label="Cancel" title="Go to Project list page" severity="secondary" class="mr-2"
                        @click="fnBackAlert" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
                <Button label="Create Project" title="Create project" @click="submitForm"
                        icon="pi pi-plus-circle" iconPos="right"></Button>
            </div>
        </form>
        <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
    </div>
</template>
  
<script>
import FormInput from "@/components/FormInput";
    import { userService } from "@/services/UserService.js";
    import { catalogService } from "@/services/CatalogService.js";
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import LoaderComponent from "@/components/LoaderComponent";

    import Button from 'primevue/button';
    import { ToastSeverity } from 'primevue/api';

export default {
  name: "CreateProjectPage",
  components: {
      Button,
      FormInput, LoaderComponent 
  },
  setup() { return { v$: useVuelidate() } },
  data() {
            return {
              createProjectForm: {
                    name: "",
                    estimatedSize: "",
                    noiceLevel: "",
                    comments: "",
                    workingWindowStart: "",
                    workingWindowEnd: "",
                    longitude:"",
                    latitude:"",
                    imageBase64: null
                },
                isLoading: false
            }
        },
validations() {
    return {
      createProjectForm: {
            name: { required },
            estimatedSize: { required },
            noiceLevel: { required },
            //workingWindowStart: { required },
            //workingWindowEnd: { required },
        }
    }
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
  methods:{
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.createProjectForm.imageBase64 = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
        //console.log('Form submitted:', this.createProjectForm);
        this.v$.$validate()
            .then(result => {
                if(result){
                    this.isLoading = true;
                    catalogService.saveProject(this.createProjectForm).then((data) => {
                    console.log(data);
                    this.resetForm();

                    this.setSelectedProject(data.inserted_id, this.createProjectForm.name);
                    //adding the id to project configurator page
                    this.$router.push({ name: 'project-configurator', params: { id: data.inserted_id }});

                        this.isLoading = false;
                        
                }).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                });
                }else{
                console.log(result)
                //console.log("errr")
                }
            })
            .catch(error => {
                console.log(error);
            });
      },
      setSelectedProject(projectId, projectName) {
          this.$store.commit("SET_PROJECT", projectId, projectName);
          
          userService
              .setProject(this.$store.getters.username, projectId)
              .then((data) => {
                  console.log(data);
                  this.$emitter.emit("changeProject", projectId, projectName);
              })
              .catch((error) => {
                  console.log(error);
                  this.fnShowToast("Error", "An error occurred when trying to select the project!", ToastSeverity.ERROR);
              });
      },
    resetForm(){
      this.createProjectForm = {
        name: "",
        estimatedSize: "",
        noiceLevel: "",
        comments: "",
        longitude:"",
        latitude:"",
        //workingWindowStart: "",
        //workingWindowEnd: "",
        imageBase64: null
      };
      this.v$.$reset();
      this.$refs.projectImage.value = '';
    },
    fnBackAlert(){
        this.$router.push({ name: "select-project" });
    }
  }
};

</script>
  
<style scoped>

</style>