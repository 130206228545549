<template>
    <div>
      <h2 class="text-4xl leading-tight text-center my-4">
        Incidents Report
      </h2>
      <div style="justify-items: center;">
        <dynamic-widget-component
          tab="structural-integrity-incidents"
        ></dynamic-widget-component>       
      </div>
    </div>
  </template>
  
  <script>
  import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
  
  export default {
      name: "StructuralIntegrityPage",
      components: { DynamicWidgetComponent },
      mounted() {
          this.$logService.save(this.$options.name);
      },
  };
  </script>
  